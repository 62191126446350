import React, { Component, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import { fetchAuthorization_API, FetchWebsiteTempatInfo_API, fetchcustomercart_API, fetchorderhistory_API } from './components/API/API';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
export const Contexthandlerscontext = React.createContext();
export const Contexthandlerscontext_provider = (props) => {
    let history = useHistory();
    const { lang, setlang, langdetect } = React.useContext(LanguageContext);
    const setroutedispatcherfunccontext = (route) => {
        history.push(route);
    };
    const [countryshowmodalContext, setcountryshowmodalContext] = useState(false);
    const [pageinnerclasscontext, setpageinnerclasscontext] = useState('');
    const fetchAuthorizationQueryContext = useQuery(['fetchAuthorization_API'], () => fetchAuthorization_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    const FetchWebsiteTempatInfoQueryContext = useQuery(['FetchWebsiteTempatInfo_API'], () => FetchWebsiteTempatInfo_API({ apptype: 'webapp' }), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    const fetchcustomercartQueryContext = useQuery(['fetchcustomercart_API'], () => fetchcustomercart_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    const fetchorderhistoryQueryContext = useQuery(['fetchorderhistory_API'], () => fetchorderhistory_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });

    const [sidecartnavshowcontext, setsidecartnavshowcontext] = useState(false);

    const [sidenavarray_context, setsidenavarray_context] = useState([
        {
            pagename: 'Home',
            isselected: false,
            reactpath: '/home',
        },
        {
            pagename: lang.shop,
            isselected: false,
            reactpath: '/home/hop',
        },
        {
            pagename: lang.collections,
            isselected: false,
            reactpath: '/home/collections',
        },
    ]);
    const [pagetitle_context, setpagetitle_context] = useState('');
    const setsidenavactive_context = (route) => {
        // var temparr = [...sidenavarray_context];
        // temparr.forEach((sideelement, index) => {
        //     if (sideelement.reactpath == route) {
        //         sideelement['isselected'] = true;
        //     } else {
        //         sideelement['isselected'] = false;
        //     }
        // });
        // setsidenavarray_context([...temparr]);
    };
    return (
        <Contexthandlerscontext.Provider
            value={{
                sidenavarray_context,
                setsidenavarray_context,
                pagetitle_context,
                setpagetitle_context,
                setsidenavactive_context,
                setroutedispatcherfunccontext,
                sidecartnavshowcontext,
                setsidecartnavshowcontext,
                fetchAuthorizationQueryContext,
                FetchWebsiteTempatInfoQueryContext,
                fetchcustomercartQueryContext,
                fetchorderhistoryQueryContext,

                pageinnerclasscontext,
                setpageinnerclasscontext,
                countryshowmodalContext,
                setcountryshowmodalContext,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
