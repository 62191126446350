import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { serverbaselink, instapikey } from '../Env_Variables';
import { instapikey } from '../../Env_Variables';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const country = cookies.get('country');
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    country: country,
};

const serverbaselinkfunc = () => {
    // var serverbaselinktemp = 'https://dev3-tejar.com';
    var serverbaselinktemp = 'https://tejarrtech.com';

    return serverbaselinktemp;
};
const addinstpaikeytoobject = (payload) => {
    var newpayload = { ...payload };
    if (newpayload != undefined) {
        newpayload.instapikey = instapikey;
    } else {
        newpayload = { instapikey: instapikey };
    }

    return newpayload;
};
export const FetchWebsiteTempatInfo_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchwebsitetemplateinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchAuthorization_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchAuthorization',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const UpdateOrder_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/UpdateOrder',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchproducts_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchproducts',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchProductInfo_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchproductinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchcustomercart_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchcustomercart',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const AddtoCart_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/addtocart',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchFavoriteProducts_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchfavoriteproducts',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ApplyPromoCode_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/applypromocode',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchTabexCountriesStates_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchTabexCountriesStates',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ChooseState_Country_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/ChooseState_Country',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const AddOrder_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/addorderaftergroupby_agelstatus',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchcollections_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchcollections',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchCategories_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchcategories',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchorderhistory_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchorderhistory',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchinstitutecustomerinfosignup_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchinstitutecustomerinfosignup',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchInstitutePolicies_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchInstitutePolicies',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const CustomerSignUp_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/signup',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Login_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/login',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Logout_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/logout',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ViewersAdderReport_API = (axiosdata) => {
    // axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/ViewersAdderReport',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const SearcHeader_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/SearcHeader',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const UpdateCartVendorinfo_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/UpdateCartVendorinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchRelatedProducts_API = (axiosdata) => {
    var axiosdatatemp = { ...axiosdata };
    axiosdatatemp.instapikey = instapikey();
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/FetchRelatedProducts',
        headers: axiosheaders,
        data: axiosdatatemp,
    });
    return axiosfetch;
};
export const VerifyPhonenumber_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/VerifyPhonenumber',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const usesubwalletcreditpoints_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/usesubwalletcreditpoints',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const usecashbackcreditpoints_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/usecashbackcreditpoints',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ReturnItem_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/returnitem',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
