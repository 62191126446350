import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Contexthandlerscontext } from './Contexthandlerscontext';
import { fetchAuthorization_API, Logout_API, Login_API, AddtoCart_API, ApplyPromoCode_API, VerifyPhonenumber_API, UpdateOrder_API, fetchCategories_API } from './components/API/API';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { LanguageContext } from './LanguageContext';
import Cookies from 'universal-cookie';
import { instapikey } from './Env_Variables';
export const Routedispatcher = React.createContext();
export const Routedispatcherprovider = (props) => {
    let history = useHistory();
    const queryClient = useQueryClient();
    const { lang, setlang, langdetect } = React.useContext(LanguageContext);
    const { fetchAuthorizationQueryContext, fetchorderhistoryQueryContext } = useContext(Contexthandlerscontext);
    const [exchangeobjContext, setexchangeobjContext] = useState({
        mode: false,
        vendorid: '',
        vendorname: '',
        totalreturnprice: 0,
        returnitems: [],
        newitems: [],
        totalnewitemsprice: 0,
        orderid: '',
    });
    const [currentcategoriesshowingvendoridcontext, setcurrentcategoriesshowingvendoridcontext] = useState('all');
    const [showcartslidercontext, setshowcartslidercontext] = useState(false);
    const [favoriteprojectscountContext, setfavoriteprojectscountContext] = useState([]);
    const clearexchangeobjcontext = () => {
        setexchangeobjContext({
            mode: false,
            vendorid: '',
            vendorname: '',
            totalreturnprice: 0,
            returnitems: [],
            newitems: [],
            totalnewitemsprice: 0,
            orderid: '',
        });
        setcurrentcategoriesshowingvendoridcontext('all');
    };
    useEffect(() => {
        const cookies = new Cookies();
        var favoriteproducts = cookies.get('favoriteproducts');
        if (favoriteproducts == undefined) {
            setfavoriteprojectscountContext([]);
        } else {
            setfavoriteprojectscountContext(favoriteproducts.products);
        }
    }, []);
    const routedispatchercontext = (route) => {
        history.push(route);
    };
    const fetchCategoriesQueryContext = useQuery(['fetchCategories_API' + currentcategoriesshowingvendoridcontext], () => fetchCategories_API({ vendorid: currentcategoriesshowingvendoridcontext }), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    const LogoutMutationContext = useMutation('Logout_API', {
        mutationFn: Logout_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error Logging Out.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                fetchAuthorizationQueryContext.refetch();
                routedispatchercontext('/home');
                NotificationManager.success('', lang.loggedoutsuccess);
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const LoginMutationContext = useMutation('Login_API', {
        mutationFn: Login_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error Logging In.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                fetchAuthorizationQueryContext.refetch();

                routedispatchercontext('/home');

                NotificationManager.success('', lang.loggedinsuccess);
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const deletecartitemContext = (cartitemobj) => {
        var payloadobj = {
            functype: 'delete',
            custcaritemid: cartitemobj.custcaritemid,
            cartitemobj: cartitemobj,
        };
        AddtoCartMutationContext.mutate(payloadobj);
    };
    const ChangeCartItemQuantityContext = (cartitemobj, newquantity) => {
        var payloadobj = {
            functype: 'ChangeCartItemQuantity',
            custcaritemid: cartitemobj.custcaritemid,
            newquantity: newquantity,
            cartitemobj: cartitemobj,
        };
        AddtoCartMutationContext.mutate(payloadobj);
    };
    const AddtoCartMutationContext = useMutation('AddtoCart_API', {
        mutationFn: AddtoCart_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error In Updating Cart.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                var cartarr = queryClient.getQueryData('fetchcustomercart_API');
                cartarr.data = data.data.cartresponse;
                queryClient.setQueryData('fetchcustomercart_API', cartarr);
                NotificationManager.success('', 'Cart Updated Succesfully');
            } else {
                if (data.data.reason == 'Please Login To Proceed') {
                    // routingcountext(StaticPagesLinksContext.Login);
                }
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const ApplyPromoCodeMutationContext = useMutation('ApplyPromoCode_API', {
        mutationFn: ApplyPromoCode_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error In Applying Promo Code.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                var cartarr = queryClient.getQueryData('fetchcustomercart_API');
                cartarr.data = data.data.cartresponse;
                queryClient.setQueryData('fetchcustomercart_API', cartarr);
                if (variables.functype == 'add') {
                    // NotificationManager.success('', 'Promo Code Applied.');
                    NotificationManager.success('', 'Promo Code Applied');
                } else {
                    NotificationManager.success('', 'Promo Code Removed');
                }
            } else {
                // NotificationManager.warning('', data.data.reason);
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const addtofavoritescontext = (productid) => {
        const cookies = new Cookies();
        var favoriteproducts = cookies.get('favoriteproducts');
        if (favoriteproducts == undefined) {
            var productsarr = [];
            productsarr.push(productid);
            var obj = { instapikey: instapikey, products: productsarr };
            var jsonobj = JSON.stringify(obj);
            cookies.set('favoriteproducts', jsonobj, { path: '/' });
            // NotificationManager.success('', lang.addedtowishlist);
        } else {
            var addproductchecker = true;
            var parsedfavproducts = favoriteproducts;
            // console.log(parsedfavproducts);
            parsedfavproducts.products.forEach(function (arrayItem, arrayindex) {
                if (arrayItem == productid) {
                    parsedfavproducts.products.splice(arrayindex, 1);
                    addproductchecker = false;
                }
            });
            if (addproductchecker) {
                parsedfavproducts.products.push(productid);
                // NotificationManager.success('', lang.addedtowishlist);
            } else {
                // NotificationManager.success('', lang.productrmovedfromwishlist);
            }
            setfavoriteprojectscountContext(parsedfavproducts.products);
            var jsonfavproducts = JSON.stringify(parsedfavproducts);
            cookies.set('favoriteproducts', jsonfavproducts, { path: '/' });
        }
    };
    const VerifyPhoneNumberMutationContext = useMutation('VerifyPhonenumber_API', {
        mutationFn: VerifyPhonenumber_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error Sending SMS.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                alert('sent');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const ChangeOrderItemQuantityContext = (cartitemobj, newquantity) => {
        var payloadobj = {
            functype: 'ChangeOrderItemQuantity',
            orderitemid: cartitemobj.orderitemid,
            orderid: cartitemobj.orderid,
            newquantity: newquantity,
        };
        UpdateOrderMutationContext.mutate(payloadobj);
    };
    const UpdateOrderMutationContext = useMutation('UpdateOrder_API', {
        mutationFn: UpdateOrder_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error Updating Order.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                fetchAuthorizationQueryContext.refetch();
                fetchorderhistoryQueryContext.refetch();
                NotificationManager.success('', 'تم التعديل');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    return (
        <Routedispatcher.Provider
            value={{
                routedispatchercontext,
                LogoutMutationContext,
                LoginMutationContext,
                favoriteprojectscountContext,
                setfavoriteprojectscountContext,
                showcartslidercontext,
                setshowcartslidercontext,
                AddtoCartMutationContext,
                deletecartitemContext,
                ChangeCartItemQuantityContext,
                addtofavoritescontext,
                ApplyPromoCodeMutationContext,
                VerifyPhoneNumberMutationContext,
                UpdateOrderMutationContext,
                ChangeOrderItemQuantityContext,
                setexchangeobjContext,
                exchangeobjContext,
                clearexchangeobjcontext,
                fetchCategoriesQueryContext,
                currentcategoriesshowingvendoridcontext,
                setcurrentcategoriesshowingvendoridcontext,
            }}
        >
            {props.children}
        </Routedispatcher.Provider>
    );
};
